import { EnCAStorage } from '@core/cross-analytics/enums/cross-analytics.enum';
import {
  IPageDuration,
  IPageTimeKeys,
  ITimeUnits,
} from '@core/cross-analytics/interfaces/page-time.interface';
import { AVAILABLE_LANGS, TranslateLang } from '@translate/constants/available-langs';

const padTimeUnit = (value: number): string => value.toString().padStart(2, '0');

const calculateTimeUnits = (milliseconds: number): ITimeUnits => {
  const seconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return {
    hours: padTimeUnit(hours),
    minutes: padTimeUnit(minutes),
    seconds: padTimeUnit(remainingSeconds),
  };
};

const formatToTimeString = ({ hours, minutes, seconds }: ITimeUnits): string =>
  `${hours}:${minutes}:${seconds}`;

export const formatTimeDuration = (timestamp: string): string =>
  formatToTimeString(calculateTimeUnits(Date.now() - parseInt(timestamp)));

export const formatTimeStamp = (timestamp: string): string =>
  formatToTimeString(calculateTimeUnits(parseInt(timestamp)));

export const getTimeKeys = (pageUrl: string): IPageTimeKeys => {
  return {
    entryKey: `${EnCAStorage.PAGE_ENTRY}_${pageUrl}`,
    durationKey: `${EnCAStorage.PAGE_DURATION}_${pageUrl}`,
  };
};

export const containsPath = (url: string, basePath: string): boolean => {
  return url
    .split('/')
    .filter(segment => segment.length > 0)
    .includes(basePath);
};

export const matchExactPath = (url: string, paths: string[]): boolean => {
  return paths.includes(url);
};

export const matchPathIgnoringLocale = (url: string, paths: string[]): boolean => {
  const segments: string[] = url.split('/').filter(s => s.length > 0);
  const normalizedUrl: string =
    segments.length > 0 && AVAILABLE_LANGS.includes(segments[0] as TranslateLang)
      ? '/' + segments.slice(1).join('/')
      : url;

  return paths.includes(normalizedUrl);
};

export const calculateDuration = (entryTime: string, existingDuration?: string): IPageDuration => {
  const currentDuration: number = Date.now() - parseInt(entryTime);
  const totalDuration: number = existingDuration
    ? currentDuration + parseInt(existingDuration)
    : currentDuration;

  return { currentDuration, totalDuration };
};
