import { computed, inject, Injectable, OnDestroy, Signal } from '@angular/core';
import { ILocation } from '@core/cross-analytics/interfaces/location.interface';
import { PlatformService } from '@noda-lib/utils/services';
import { catchError, from, Observable, Subject, take } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LocationService implements OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  private platformService: PlatformService = inject(PlatformService);
  private navigator: Signal<Navigator | null> = computed(() =>
    this.platformService.isBrowser() ? navigator : null,
  );

  private _location: ILocation | null = null;

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public saveUserLocation(): Observable<GeolocationCoordinates> {
    return from(this.getCurrentPosition()).pipe(
      take(1),
      tap((coords: GeolocationCoordinates): void => {
        this._location = {
          latitude: coords.latitude,
          longitude: coords.longitude,
        };
      }),
      catchError((error: unknown) => {
        console.error('Error when receiving geolocation:', error);
        throw error;
      }),
      takeUntil(this.destroy$),
    );
  }

  public get location(): ILocation | null {
    return this._location;
  }

  private getCurrentPosition(): Promise<GeolocationCoordinates> {
    return new Promise((resolve, reject) => {
      if (this.navigator()) {
        if (!this.navigator().geolocation) {
          reject('Geolocation is not supported by a browser');

          return;
        }

        this.navigator().geolocation.getCurrentPosition(
          (position: GeolocationPosition) => resolve(position.coords),
          (error: GeolocationPositionError) => reject(error),
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          },
        );
      }
    });
  }
}
