import { inject, Injectable } from '@angular/core';
import { DEMO_PAGE_TRACKED_PATHS } from '@core/cross-analytics/constants/ca-tracked-paths';
import { EnCAStorage } from '@core/cross-analytics/enums/cross-analytics.enum';
import { IPageTimeKeys } from '@core/cross-analytics/interfaces/page-time.interface';
import {
  calculateDuration,
  formatTimeStamp,
  getTimeKeys,
  matchPathIgnoringLocale,
} from '@core/cross-analytics/utils/utils-analytics';
import { RoutingService } from '@core/services/routing.service';
import { ISimple } from '@noda-lib/utils/interfaces';
import { UtilsStorageService } from '@noda-lib/utils/services';

@Injectable({
  providedIn: 'root',
})
export class PageTimeService {
  private storageService: UtilsStorageService = inject(UtilsStorageService);
  private routingService: RoutingService = inject(RoutingService);

  public getAllPagesDuration(): ISimple<string> {
    const currUrl: string = this.routingService.getCurrentRoute();

    const allDurations: ISimple<string> = this.storageService
      .getKeys('sessionStorage')
      .filter((key: string) => key.startsWith(EnCAStorage.PAGE_DURATION))
      .reduce<ISimple<string>>((result, key) => {
        const duration: string = this.storageService.getItem(key, 'sessionStorage');
        if (duration) {
          result[key] = formatTimeStamp(duration);
        }

        return result;
      }, {});

    if (matchPathIgnoringLocale(currUrl, DEMO_PAGE_TRACKED_PATHS)) {
      const keys: IPageTimeKeys = getTimeKeys(currUrl);
      const { entryTime, existingDuration } = this.getDurationFromStorage(keys);

      if (entryTime) {
        const { totalDuration } = calculateDuration(entryTime, existingDuration);
        allDurations[keys.durationKey] = formatTimeStamp(totalDuration.toString());
      }
    }

    return allDurations;
  }

  public setPageEntryTime(currUrl: string): void {
    if (matchPathIgnoringLocale(currUrl, DEMO_PAGE_TRACKED_PATHS)) {
      const { entryKey } = getTimeKeys(currUrl);
      this.storageService.setItem(entryKey, Date.now().toString(), 'sessionStorage');
    }
  }

  public setPageDurationTime(prevUrl: string): void {
    if (matchPathIgnoringLocale(prevUrl, DEMO_PAGE_TRACKED_PATHS)) {
      const keys: IPageTimeKeys = getTimeKeys(prevUrl);
      const { entryTime, existingDuration } = this.getDurationFromStorage(keys);

      if (entryTime) {
        const { totalDuration } = calculateDuration(entryTime, existingDuration);
        this.storageService.setItem(keys.durationKey, totalDuration.toString(), 'sessionStorage');
      }
    }
  }

  private getDurationFromStorage(keys: IPageTimeKeys): {
    entryTime?: string;
    existingDuration?: string;
  } {
    return {
      entryTime: this.storageService.getItem(keys.entryKey, 'sessionStorage'),
      existingDuration: this.storageService.getItem(keys.durationKey, 'sessionStorage'),
    };
  }
}
