import { inject, Injectable } from '@angular/core';
import { DEMO_PAGE_TRACKED_PATHS } from '@core/cross-analytics/constants/ca-tracked-paths';
import { EnCAEvents, EnCAStorage } from '@core/cross-analytics/enums/cross-analytics.enum';
import { LocationService } from '@core/cross-analytics/services/location.service';
import { PageTimeService } from '@core/cross-analytics/services/page-time.service';
import {
  containsPath,
  formatTimeDuration,
  matchPathIgnoringLocale,
} from '@core/cross-analytics/utils/utils-analytics';
import { RoutingService } from '@core/services/routing.service';
import { CrossAnalyticsService } from '@noda-lib/cross-analytics';
import { CookiesService } from '@noda-lib/utils/cookies';
import { ISimple } from '@noda-lib/utils/interfaces';
import { UtilsStorageService } from '@noda-lib/utils/services';
import { LanguageService } from '@translate/services/language.service';

@Injectable({
  providedIn: 'root',
})
export class DemoPageAnalyticsService {
  private caService: CrossAnalyticsService = inject(CrossAnalyticsService, { optional: true });
  private storageService: UtilsStorageService = inject(UtilsStorageService);
  private cookiesService: CookiesService = inject(CookiesService);
  private locationService: LocationService = inject(LocationService);
  private routingService: RoutingService = inject(RoutingService);
  private pageTimeService: PageTimeService = inject(PageTimeService);
  private languageService: LanguageService = inject(LanguageService);

  public trackUrl(prevUrl: string, currUrl: string): void {
    this.pageTimeService.setPageEntryTime(currUrl);
    this.pageTimeService.setPageDurationTime(prevUrl);
    this.trackPageVisit(prevUrl, currUrl);
  }

  public trackClick(eventType: EnCAEvents, customValue?: ISimple<string>): void {
    this.sendCAEvent(eventType, customValue);
  }

  public trackOutsideClick(eventType: EnCAEvents): void {
    const url: string = this.routingService.getCurrentRoute().split('?')[0];
    if (matchPathIgnoringLocale(url, DEMO_PAGE_TRACKED_PATHS)) {
      this.sendCAEvent(eventType);
    }
  }

  public setFeatureInitTime(url: string): void {
    if (matchPathIgnoringLocale(url, DEMO_PAGE_TRACKED_PATHS)) {
      this.pageTimeService.setPageEntryTime(url);
    }
  }

  private trackPageVisit(prevUrl: string, currUrl: string): void {
    if (containsPath(currUrl, 'demo')) {
      this.sendCAEvent(EnCAEvents.PAGE_VISIT);
    } else if (
      !containsPath(currUrl, 'demo') &&
      matchPathIgnoringLocale(prevUrl, DEMO_PAGE_TRACKED_PATHS)
    ) {
      this.sendCAEvent(EnCAEvents.ANOTHER_PAGE_CHOSEN);
    }
  }

  private getSessionTime(): string {
    const sessionStartTime: string = this.storageService.getItem(
      EnCAStorage.SESSION_INIT,
      'sessionStorage',
    );

    return sessionStartTime ? formatTimeDuration(sessionStartTime) : '00:00:00';
  }

  private sendCAEvent(eventType: EnCAEvents, customValue?: ISimple<string>): void {
    this.caService?.post({
      eventType,
      customData: {
        location: this.locationService.location,
        sessionDuration: this.getSessionTime(),
        timeOfDay: new Date().toString(),
        timeOnFeature: this.pageTimeService.getAllPagesDuration(),
        referrer: this.cookiesService.get(EnCAStorage.FIRST_USER_ENTRY),
        lang: this.languageService.activeLang,
        ...customValue,
      },
    });
  }
}
