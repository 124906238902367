export enum EnCAEvents {
  // default
  USER_LANDED = 'USER_LANDED',
  USER_LEFT = 'USER_LEFT',

  // origin
  LANGUAGE_CHANGED = 'LANGUAGE_CHANGED',

  // demo page
  PAGE_VISIT = 'PAGE_VISIT',
  TRY_DEMO_OPEN_BANKING_PAYMENTS = 'TRY_DEMO_OPEN_BANKING_PAYMENTS',
  SEE_MORE_PAY_AND_GO = 'SEE_MORE_PAY_AND_GO',
  ANOTHER_PAGE_CHOSEN = 'ANOTHER_PAGE_CHOSEN',

  // demo payment flow
  START_USING_NODA = 'START_USING_NODA',
  CHOOSE_COUNTRY_PAYMENT_FLOW = 'CHOOSE_COUNTRY_PAYMENT_FLOW',
  COUNTRY_CHOSEN_PAYMENT_FLOW = 'COUNTRY_CHOSEN_PAYMENT_FLOW',
  MONEY_CHOSEN = 'MONEY_CHOSEN',
  MONEY_WRITTEN = 'MONEY_WRITTEN',
  DONATE_NOW = 'DONATE_NOW',

  // demo pay and go
  PAY_GO_LIGHT = 'PAY_GO_LIGHT',
  PAY_GO_HARD = 'PAY_GO_HARD',

  // demo page breadcrumbs
  GO_HOME = 'GO_HOME',
  BACK_TO_DEMO = 'BACK_TO_DEMO',

  // intercom
  SUPPORT = 'SUPPORT',
  SEND_MESSAGE = 'SEND_MESSAGE',
  MESSAGE_SENT = 'MESSAGE_SENT',
}

export enum EnCAStorage {
  SESSION_INIT = 'sessionInitTime',
  PAGE_ENTRY = 'pageEntry',
  PAGE_DURATION = 'pageDuration',
  FIRST_USER_ENTRY = 'firstUserEntryUTM',
}
